import React, { useState } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import request from '../../assets/images/request-a-demo.jpeg'
import ReCAPTCHA from "react-google-recaptcha";

const WhyChooseUs = () => {

    const [values, setvalues] = useState({ name: "", email: "", phone_number: "", coutry: "", form_check: "", message: "", products: [] })
    const [token, setToken] = useState("")
  
   
    const [status, setStatus] = useState( {class:"", status : false , message: ""});
    

   
    const onChange = (e) => {
        setvalues({ ...values, [e.target.name]: e.target.value });
    }


    const radioButton = (e) => {

        if (e.target.checked) {
            setvalues({ ...values, products: [...values.products, e.target.value] })
        } else {
            setvalues({ ...values, products: values.products.filter(x => x !== e.target.value) })
        }

    }

    
    async function handleSubmit(e) {
        if (values) {

        }
        
        e.preventDefault();
        try {
            const rawResponse = await fetch(process.env.CAPTCHA_VERIFY_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...values, token })
            });

            const content = await rawResponse.json();
            if (content.status){
                setStatus({class : "alert-success", status : true, message : "Request Successfuly recieved" })
            }else{
            setStatus({class : "alert-danger", status : true, message : content.message || "Please Fill the required fields" })

        }
        } catch (error) {
            setStatus({class : "alert-danger", status : true, message : error.message || "Server error" })
        }
    }



    let submit = (token) => {
        setToken(token)
    }


    const checkboxs = [{ value: "Blood Banking Management Information System" },
    { value: "Patient Blood Management System" }
    ]

    return (
        <section className="how-its-work-area pt-5" style={{ backgroundColor: 'white' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12 mb-auto">
                        <div className='text-center'>
                            <div className="image">
                                <img src={request} alt="Request a Demo" />
                            </div>
                        </div>
                        <div className="how-its-work-content pb-5">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" />
                                Request a personalized demo
                            </span>
                            <h2>What can I expect</h2>
                            <p>A brief conversation to assess how we can help you to asses  your need.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Live product demo</h3>
                                    <p>Give you insight, how to get the most benefit from our products.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Guide you </h3>
                                    <p>To follow the best path forward to grow your business.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Helps you</h3>
                                    <p>To digitalize your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group position-relative">
                                            <span className='required-asterick'>*</span>
                                            <input type="text" name="name" onChange={onChange} className="form-control" required placeholder="Your name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                    <div className="form-group position-relative">
                                            <span className='required-asterick'>*</span>
                                            <input type="email" name="email" onChange={onChange} className="form-control" required placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" onChange={onChange} className="form-control" placeholder="Your phone number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group position-relative">
                                            <span className='required-asterick'>*</span>
                                            <input type="text" name="Designation" onChange={onChange} className="form-control" required placeholder="Designation" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group position-relative">
                                            <span className='required-asterick'>*</span>
                                            <input type="text" name="Organization Name" onChange={onChange} className="form-control" required placeholder="Organization Name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 mb-4" >

                                        <select id="country" selected="Coutry" name="country" className="form-control" required onChange={onChange} >
                                            <option value="Afghanistan">Select Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Islands">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-bissau">Guinea-bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Helena">Saint Helena</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-leste">Timor-leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="Reference" onChange={onChange} className="form-control" required placeholder="From where did you get info about us ?" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="brief info" onChange={onChange} className="form-control" cols="30" rows="6" required placeholder="Your brief introduction" />
                                            </div>

                                        </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form_check" >
                                            <h6> Choose products for demo <span className='color-orange'>*</span> </h6>
                                            <div className="entry-meta">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                checkboxs.map(checkbox => (

                                                                    <td key={checkbox.value} style={{ padding: 10 }}>
                                                                        <div className="comment-form-cookies-consent">
                                                                            <div><input type="checkbox" value={checkbox.value} onChange={radioButton} name="comment-cookies-consent" id={checkbox.value} style={{ margin: 5 }} /></div>
                                                                            <div><label className="form-check-label" htmlFor={checkbox.value}> {checkbox.value}</label></div>
                                                                        </div>
                                                                    </td>
                                                                ))
                                                            }

                                                        </tr>
                                                        <tr>
                                                        <td style={{ padding: 10 }}>
                                                                <div className="comment-form-cookies-consent">
                                                                    <div><input type="checkbox" value="WFM" onChange={radioButton} name="comment-cookies-consent" id="comment-cookies-consent-wfm" style={{ margin: 5 }} /></div>
                                                                    <div><label htmlFor="comment-cookies-consent-wfm"> Workforce Management Software</label></div>
                                                                </div>
                                                            </td>
                                                            <td style={{ padding: 10 }}>
                                                                <div className="comment-form-cookies-consent">
                                                                    <div><input type="checkbox" value="EDMS" onChange={radioButton} name="comment-cookies-consent" id="comment-cookies-consent-edms" style={{ margin: 5 }} /></div>
                                                                    <div><label htmlFor="comment-cookies-consent-edms"> Electronic Document Management System</label></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: 10 }}>
                                                                <div className="comment-form-cookies-consent">
                                                                    <div><input type="checkbox" value="ISBT 128 Labelling System" onChange={radioButton} name="comment-cookies-consent" id="comment-cookies-consent-isbt" style={{ margin: 5 }} /></div>
                                                                    <div><label htmlFor="comment-cookies-consent-isbt"> ISBT 128 Labelling System</label></div>
                                                                </div>
                                                            </td>
                                                            <td style={{ padding: 10 }}>
                                                                <div className="comment-form-cookies-consent">
                                                                    <div><input type="checkbox" value="QCT" onChange={radioButton} name="comment-cookies-consent" id="comment-cookies-consent-qct" style={{ margin: 5 }} /></div>
                                                                    <div><label htmlFor="comment-cookies-consent-qct"> Quality Control Management System</label></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                     
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-6 col-lg-6 col-md-6 pb-3 pt-3 demo-captcha">
                                       
                                    <ReCAPTCHA 
                                            sitekey="6LdLpv8cAAAAACIxxkqCtQzKOrqwaMJX1qEBmuvS"
                                            size="normal"
                                            onChange={submit}
                                        />
                                        
                                    </div>
                                   {status.status && <div className="col-lg-12 col-md-12" style={{ 'margin-top': '1rem' }} >
                                        <div class={"alert " + status.class} role="alert" >
                                         {status.message}
                                        </div>
                                    </div>}

                                    <div className="col-lg-12 col-md-12 demo-captcha">

                                        <button type="submit" className="default-btn"  >
                                            <i className="flaticon-tick"></i>
                                            Request a Demo

                                        </button>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs